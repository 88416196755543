<template>
  <div class="container pt-5">
    <h1 class="mb-3 text-secondary">Privacy policy</h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis consequatur eius inventore iure, iusto maiores molestias non obcaecati, optio reprehenderit sapiente sint velit, veritatis. Ab animi harum non sunt tempore.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Blanditiis consequatur eius inventore iure, iusto maiores molestias non obcaecati, optio reprehenderit sapiente sint velit, veritatis. Ab animi harum non sunt tempore.</p>
    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. <br>
      Blanditiis consequatur eius inventore iure, iusto maiores molestias non obcaecati, <br>
      optio reprehenderit sapiente sint velit, veritatis. Ab animi harum non sunt tempore.</p>
  </div>
</template>

<script>
export default {
  name: "Terms"
}
</script>

<style scoped>

</style>
